import { useAuth0 } from '@auth0/auth0-react';
import { useAppDispatch, useAppSelector } from 'common/hooks';
import { selectUserMe, getUserMe } from 'features/Users/slice';

export const useSession = () => {
  const dispatch = useAppDispatch();
  const { loginWithRedirect, isAuthenticated, user, logout, isLoading } =
    useAuth0();

  const userMe = useAppSelector(selectUserMe);

  const roles = user?.[process.env.REACT_APP_AUTH0_ROLE_DOMAIN!]?.roles ?? [];

  const handleLogin = () => {
    loginWithRedirect().then((response) => {
      // eslint-disable-next-line no-console
      console.log('handleLogin:', response);
    });
  };

  const handleGetUserMe = () => {
    return dispatch(getUserMe());
  };

  return {
    user: { roles, ...userMe },
    roles,
    isAuthenticated,
    isLoading,
    logout,
    login: handleLogin,
    getUserMe: handleGetUserMe,
  };
};

export default {
  useSession,
};

// 2X5894LAYL6KQ8EM1WQK6Q1W
